import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
// import tokenModule from "./modules/token.js";

Vue.use(Vuex);

const tokenName = "tokenAutopark03112022",
  tokenRefreshName = "refreshAutoparkToken03112022",
  tokenLocal = localStorage.getItem(tokenName),
  refreshTokenLocal = localStorage.getItem(tokenRefreshName);

export default new Vuex.Store({
  state: {
    user: {
      id: "",
      firstName: "",
      lastName: "",
      photo: "",
      nameOrg: ""
    },
    tokenName,
    token: tokenLocal ? tokenLocal : "",
    refreshToken: refreshTokenLocal ? refreshTokenLocal : ""
  },
  mutations: {
    SET_TOKEN(state, value) {
      state.token = value;
      localStorage.setItem(tokenName, value);
    },
    SET_TOKEN_REFRESH(state, value) {
      state.refreshToken = value;
      localStorage.setItem(tokenRefreshName, value);
    },
    SET_REMOVE_TOKENS(state) {
      state.token = "";
      localStorage.removeItem(tokenName);
      state.refreshToken = "";
      localStorage.removeItem(tokenRefreshName);
    },
    SET_USER_DATA(state, value) {
      const { id, firstName, lastName, photo, nameOrg } = value.data.response;
      state.user.id = id;
      state.user.firstName = firstName;
      state.user.lastName = lastName;
      state.user.photo = photo;
      state.user.nameOrg = nameOrg;
    }
  },
  actions: {
    GET_TOKEN({ commit }, value) {
      commit("SET_TOKEN", value);
    },
    GET_TOKEN_REFRESH({ commit }, value) {
      commit("SET_TOKEN_REFRESH", value);
    },
    REMOVE_TOKENS({ commit }) {
      commit("SET_REMOVE_TOKENS");
    },
    GET_USER_DATA({ commit }) {
      return axios
        .get("/api/autopark/info/get", {
          headers: { Authorization: `Bearer ${this.state.token}` }
        })
        .then((res) => {
          commit("SET_USER_DATA", res);
          window.dataLayer?.push({
            event: "gtagApiGet",
            gtagApiResult: {
              client_id: res.data.response.id
            }
          });
        })
        .catch(() => {
          this.$router.push("/auth");
        });
    }
  },
  modules: {
    // token: tokenModule,
  }
});

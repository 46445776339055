import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
axios.defaults.baseURL = "https://erp.tkglp.ru";
// axios.defaults.baseURL = "https://test.erp.tkglp.ru";
axios.defaults.withCredentials = true;
import router from "./router";
import store from "./store";

Vue.use(VueAxios, axios);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
